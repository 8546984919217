import {
  UserCourseInstanceModel,
  UserModuleDataModel,
} from 'api/data-contracts'
import { useState, useEffect, useCallback } from 'react'
import {
  COMPLETED,
  FEEDBACKREQUIRED,
  INPROGRESS,
  JOINED,
} from 'utils/constants/slide'

interface SideActionPanelProps {
  userCourseInstance: UserCourseInstanceModel
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>
  allFeedbacks: () => UserModuleDataModel[] | undefined
}

const SideActionPanel = ({
  userCourseInstance,
  setSelectedTabIndex,
  allFeedbacks,
}: SideActionPanelProps) => {
  const [preInfo, setPreInfo] = useState<null | string>()
  const [isModulesCompleted, setIsModulesCompleted] = useState(false)

  const findNextElement = (
    objectArray: UserModuleDataModel[],
    currentElement: UserModuleDataModel,
  ) => {
    const currentIndex = objectArray.findIndex(
      obj => obj.id === currentElement.id,
    )
    const nextElement = objectArray[currentIndex + 1]
    return nextElement !== undefined ? nextElement : null
  }

  const preInfoValueHandler = useCallback(() => {
    const inprogressModuleExist = userCourseInstance.userModuleDatas?.find(
      userModuleValue => userModuleValue.userModuleStatus === INPROGRESS,
    )

    if (inprogressModuleExist !== undefined) {
      return inprogressModuleExist
    }

    // When inprogress module is not found - check FEEDBACKREQUIRED
    if (inprogressModuleExist === undefined) {
      const lastFeedbackIfExist = userCourseInstance.userModuleDatas
        ?.filter(
          userModuleValue =>
            userModuleValue.userModuleStatus === FEEDBACKREQUIRED,
        )
        .slice(-1)

      if (lastFeedbackIfExist!.length !== 0) {
        return findNextElement(
          userCourseInstance.userModuleDatas!,
          lastFeedbackIfExist?.[0] as UserModuleDataModel,
        )
      }

      // When feedback module is not found - check COMPLETED
      if (lastFeedbackIfExist!.length === 0) {
        const completedModule = userCourseInstance.userModuleDatas
          ?.filter(
            userModuleValue => userModuleValue.userModuleStatus === COMPLETED,
          )
          .slice(-1)

        return findNextElement(
          userCourseInstance.userModuleDatas!,
          completedModule?.[0] as UserModuleDataModel,
        )
      }
    }
  }, [userCourseInstance])

  useEffect(() => {
    if (userCourseInstance.userInstanceStatus === JOINED) {
      setPreInfo(
        userCourseInstance.courseInstance?.course?.preInfo === 'undefined'
          ? null
          : userCourseInstance.courseInstance?.course?.preInfo,
      )
    }

    if (userCourseInstance.userInstanceStatus !== JOINED) {
      if (preInfoValueHandler() === null) {
        setIsModulesCompleted(true)
      }

      if (preInfoValueHandler() !== null) {
        setPreInfo(
          preInfoValueHandler()?.module?.preInfo === 'undefined'
            ? null
            : preInfoValueHandler()?.module?.preInfo,
        )

        setIsModulesCompleted(false)
      }
    }
  }, [userCourseInstance, preInfoValueHandler])

  // Latest Feedback
  const [lastfeedback, setLastFeedback] = useState<UserModuleDataModel>()
  useEffect(() => {
    const latestFeedback = allFeedbacks()!.slice(-1)
    latestFeedback!.length > 0 && setLastFeedback(latestFeedback?.[0])
  }, [allFeedbacks])

  return (
    <>
      {!isModulesCompleted && preInfo !== null && (
        <div className="w-full border-light-gray bg-light-grey rounded-medium border-[2px] p-8 mt-10">
          <p className="text-2xl font-semibold mb-3">
            {userCourseInstance.userInstanceStatus === JOINED
              ? 'Course Info'
              : 'Module Info'}
          </p>
          <hr className="border-branding-secondary border-[1px] mb-4" />
          {preInfo && preInfo !== null && (
            <p className="text-base">{preInfo}</p>
          )}
          {/* <span className="text-base underline font-semibold cursor-pointer mt-4">
            {`View actions`}
          </span> */}
        </div>
      )}

      {lastfeedback && (
        <div className="w-full border-light-gray bg-light-grey rounded-medium border-[2px] p-8 mt-6">
          <p className="text-2xl font-semibold mb-3">Feedback</p>
          <hr className="border-branding-secondary border-[1px] mb-4" />
          <p className="text-lg font-bold mb-3">
            {lastfeedback?.module?.name !== null &&
              lastfeedback?.module?.name !== undefined &&
              lastfeedback?.module?.name}
          </p>
          <p className="mb-4">
            {lastfeedback?.moduleFeedback !== null &&
              lastfeedback?.moduleFeedback !== undefined &&
              lastfeedback?.moduleFeedback}
          </p>
          <span
            className="text-base underline font-semibold cursor-pointer"
            onClick={() => setSelectedTabIndex(3)}
          >
            View feedback
          </span>
        </div>
      )}
    </>
  )
}

export default SideActionPanel
